import React, { useState } from 'react';
import utils from '../../../../utils'
import moment from 'moment-timezone';
import SettingsItem from '../../Screens/settings/SettingsItem'
import { getOptionsForBreak } from '../../../../services/frontendServices/AppointmentService'
import ApproveAppointmentsManuallyModal from '../../Screens/settings/ApproveAppointmentsManuallyModal'

function AvailabilityLimits({ timeSettings, setTimeSettings }) {
  const defaultValues = {
    reminderTimes: [
      { value: 2, text: 'שעתיים' },
      { value: 3, text: '3 שעות' },
      { value: 4, text: '4 שעות' },
      { value: 5, text: '5 שעות' },
      { value: 6, text: '6 שעות' },
      { value: 7, text: '7 שעות' },
      { value: 8, text: '8 שעות' },
      { value: 9, text: '9 שעות' },
      { value: 10, text: '10 שעות' },
      { value: 11, text: '11 שעות' },
      { value: 12, text: '12 שעות' },
      { value: 24, text: '24 שעות' },
      { value: 36, text: '36 שעות' },
      { value: 48, text: '48 שעות' }
    ],
    minTimeBeforeBooking: [
      { value: 15, text: '15 דקות' },
      { value: 30, text: '30 דקות' },
      { value: 45, text: '45 דקות' },
      { value: 60, text: 'שעה' },
      { value: 75, text: 'שעה ורבע' },
      { value: 90, text: 'שעה וחצי' },
      { value: 120, text: 'שעתיים' },
      { value: 135, text: 'שעתיים ורבע' },
      { value: 150, text: 'שעתיים וחצי' },
      { value: 180, text: 'שלוש שעות' },
      { value: 360, text: '6 שעות' },
      { value: 720, text: '12 שעות' },
      { value: 1440, text: '24 שעות' },
      { value: 2880, text: '48 שעות' },
      { value: 4320, text: '72 שעות' }
    ],
    bookEvery: [
      { value: 5, text: '5 דקות' },
      { value: 10, text: '10 דקות' },
      { value: 15, text: '15 דקות' },
      { value: 20, text: '20 דקות' },
      { value: 25, text: '25 דקות' },
      { value: 30, text: '30 דקות' },
      { value: 45, text: '45 דקות' },
      { value: 60, text: 'שעה' },
      { value: 75, text: 'שעה ורבע' },
      { value: 90, text: 'שעה וחצי' },
      { value: 120, text: 'שעתיים' },
      { value: 135, text: 'שעתיים ורבע' },
      { value: 150, text: 'שעתיים וחצי' },
      { value: 180, text: 'שלוש שעות' }
    ],
    futureBookings: [
      { value: 24, text: 'יום' },
      { value: 48, text: 'יומיים' },
      { value: 72, text: 'שלושה ימים' },
      { value: 96, text: 'ארבעה ימים' },
      { value: 120, text: 'חמישה ימים' },
      { value: 168, text: 'שבוע' },
      { value: 336, text: 'שבועיים' },
      { value: 504, text: '3 שבועות' },
      { value: 720, text: 'חודש' },
      { value: 1008, text: 'חודש וחצי' },
      { value: 1344, text: 'חודשיים' },
      { value: 2160, text: '3 חודשים' },
      { value: 4320, text: '6 חודשים' },
      { value: 27000, text: 'ללא הגבלה' }
    ]
  };

  const [bookEvery, setBookEvery] = useState(defaultValues.bookEvery.filter((item) => item.value === timeSettings.limits.bookEvery)[0] || defaultValues.bookEvery[1]);
  const [futureBookings, setFutureBookings] = useState(defaultValues.futureBookings.filter((item) => item.value === timeSettings.limits.futureBookings)[0] || defaultValues.futureBookings[8]);
  const [minTimeBeforeBooking, setMinTimeBeforeBooking] = useState(defaultValues.minTimeBeforeBooking.filter((item) => item.value === timeSettings.limits.minTimeBeforeBooking)[0] || defaultValues.minTimeBeforeBooking[0]);
  const [reminderTime, setReminderTime] = useState(defaultValues.reminderTimes.filter((item) => item.value === timeSettings.limits.reminderTime)[0] || defaultValues.reminderTimes[defaultValues.reminderTimes.length - 1]);
  const [approveAppointmentsManuallyModalOpen, setApproveAppointmentsManuallyModalOpen] = useState(false)

  const bookEveryChangeHandler = (event) => {
    const selectedTime = event.target.value;
    defaultValues.bookEvery.map((item) => {
      if (item.value == selectedTime) {
        setBookEvery(item);
      }
    });
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        bookEvery: parseInt(selectedTime)
      }
    }))
  }

  const smartTimeOptimizationChangeHandler = (event) => {
    const option = event.target.value;
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        smartTimeOptimization: option === 'true'
      }
    }))
  }

  const allowMultipleBookingsChangeHandler = (event) => {
    const option = event.target.value;
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        allowMultipleBookings: option === 'true'
      }
    }))
  }

  const ignoreServiceLengthChangeHandler = (event) => {
    const option = event.target.value;
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        ignoreServiceLength: option === 'true'
      }
    }))
  }

  const futureBookingsChangeHandler = (event) => {
    const selectedTime = parseInt(event.target.value);

    defaultValues.futureBookings.map((item) => {
      if (item.value === selectedTime) {
        setFutureBookings(item);
      }
    });
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        futureBookings: selectedTime
      }
    }))
  }

  const minTimeBeforeBookingChangeHandler = (event) => {
    const option = parseInt(event.target.value);
    defaultValues.minTimeBeforeBooking.map((item) => {
      if (item.value === option) {
        setMinTimeBeforeBooking(item);
      }
    });
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        minTimeBeforeBooking: option
      }
    }))
  }

  const reminderTimeChangeHandler = (event) => {
    const option = parseInt(event.target.value);
    defaultValues.reminderTimes.map((item) => {
      if (item.value === option) {
        setReminderTime(item);
      }
    });
    setTimeSettings(prev => ({
      ...prev,
      limits: {
        ...prev.limits,
        reminderTime: option
      }
    }))
  }

  function onChangeBreak({ target }) {
    if (!timeSettings.limits.smartTimeOptimization) return
    setTimeSettings(prevTimeSettings => ({
      ...prevTimeSettings,
      limits: {
        ...prevTimeSettings.limits,
        breakBetweenAppointments: +target.value
      }
    }))
  }

  function onChangeManualAppointmentApproval({ target }) {
    const { value } = target
    if (timeSettings.limits.approveAppointmentsManually && value === 'false') {
      setApproveAppointmentsManuallyModalOpen(true)
      return
    }

    setTimeSettings(prevTimeSettings => ({
      ...prevTimeSettings,
      limits: {
        ...prevTimeSettings.limits,
        approveAppointmentsManually: value === 'true'
      }
    }))
  }

  return (
    <>
      <div className='border text-sm rounded-sm p-3'>
        <div
          className='flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-5 md:space-x-reverse'>
          <div className='md:w-1/2 space-y-1'>
            <label className="font-bold flex items-center">
              <div className="label-text md:text-base text-gray-800">ניהול זמנים חכם</div>
              <span
                className="inline-flex items-center justify-center px-2 py-0.5 mr-2 text-xs font-bold leading-none text-white bg-green-400 rounded-full">
                מומלץ מאוד
              </span>
            </label>
            <p className='text-sm md:text-sm text-gray-700'>
              המערכת תנהל את היומן שלכם בצורה חכמה ואוטומטית ותתחשב באורך כל שירות, בכדי שתוכלו לקבל כמה שיותר לקוחות במהלך היום.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <select
              className='select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full'
              onChange={smartTimeOptimizationChangeHandler}
              value={timeSettings.limits.smartTimeOptimization}>
              <option className='text-center' value={true}>✅ פעיל (מומלץ מאוד)</option>
              <option className='text-center' value={false}>❌ לא פעיל</option>
            </select>
          </div>
        </div>

        <div className={`mt-5 ${!timeSettings.limits.smartTimeOptimization ? 'opacity-100' : 'opacity-50'}`}>
          <div className='border-r-2 bg-gray-50'>
            <div className='border text-sm rounded-sm p-3'>
              <div
                className='md:space-x-5 md:space-x-reverse flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0'>
                <div className='md:w-1/2 space-y-1'>
                  <label className="font-bold">
                    <div className="label-text md:text-base text-gray-800">קביעת תור</div>
                  </label>
                  <div className='text-sm md:text-sm text-gray-700'>
                    ניתן לקבוע תור כל <strong className='underline'>{utils.timeConvert(bookEvery.value)}</strong>
                    <div className='space-x-1 space-x-reverse'>
                      <span>לדוגמא:</span>
                      <span className='bg-yellow-100 p-0.5 text-xs rounded font-medium text-yellow-600'>
                        {moment('1970-01-01T08:00').add(bookEvery.value * 3, 'minutes').format('hh:mm')}
                      </span>
                      <span className='bg-yellow-100 p-0.5 text-xs rounded font-medium text-yellow-600'>
                        {moment('1970-01-01T08:00').add(bookEvery.value * 2, 'minutes').format('hh:mm')}
                      </span>
                      <span className='bg-yellow-100 p-0.5 text-xs rounded font-medium text-yellow-600'>
                        {moment('1970-01-01T08:00').add(bookEvery.value, 'minutes').format('hh:mm')}
                      </span>
                      <span className='bg-yellow-100 p-0.5 text-xs rounded font-medium text-yellow-600'>
                        {moment('1970-01-01T08:00').format('hh:mm')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2'>
                  <select disabled={timeSettings.limits.smartTimeOptimization}
                    className="select select-bordered bg-white border border-gray-300 select-md w-full border px-3 font-medium h-12 bg-white w-full"
                    onChange={bookEveryChangeHandler}
                    value={bookEvery.value}>
                    {
                      defaultValues.bookEvery.map((time) => (
                        <option className='text-center' key={time.text}
                          value={time.value}>{utils.timeConvert(time.value)}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='border-r-2 bg-gray-50'>
            <div className='border text-sm rounded-sm p-3'>
              <div
                className='md:space-x-5 md:space-x-reverse flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0'>
                <div className='md:w-1/2 space-y-1'>
                  <label className="font-bold flex items-center">
                    <div className="label-text md:text-base text-gray-800">התעלמות ממשך השירות</div>
                  </label>
                  <p className='text-sm md:text-sm text-gray-700'>
                    האם לאפשר קבלת לקוחות נוספים בין לבין? לדוגמא: לקוחה מגיעה בשעה 09:00 לטיפול עד 12:00, אך תוכלו לקבל לקוחה נוספת בשעה <span
                      className='bg-yellow-100 p-0.5 text-xs rounded font-medium text-yellow-600'>{moment('1970-01-01T09:00').add(bookEvery.value, 'minutes').format('hh:mm')}</span>.
                  </p>
                </div>
                <div className='w-full md:w-1/2'>
                  <select disabled={timeSettings.limits.smartTimeOptimization}
                    className='select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full'
                    onChange={ignoreServiceLengthChangeHandler}
                    value={timeSettings.limits.ignoreServiceLength}>
                    <option className='text-center' value={false}>✅ אל תתעלם (מומלץ)</option>
                    <option className='text-center' value={true}>❌ התעלם</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SettingsItem title={'הפסקה בין תור לתור'} value={timeSettings.limits.breakBetweenAppointments} onChange={onChangeBreak}
        options={getOptionsForBreak(defaultValues.bookEvery)} isDisabled={!timeSettings.limits.smartTimeOptimization}>
        {
          timeSettings.limits.breakBetweenAppointments ?
            <>תורים ייקבעו עם הפסקה של <strong className='underline'>{timeSettings.limits.breakBetweenAppointments}</strong> דקות.</>
            :
            <>התורים ייקבעו ללא הפסקה ביניהם.</>
        }
      </SettingsItem>

      <div className='border text-sm rounded-sm p-3'>
        <div
          className='md:space-x-5 md:space-x-reverse flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0'>
          <div className='md:w-1/2 space-y-1'>
            <label className="font-bold">
              <div className="label-text md:text-base text-gray-800">תור עתידי</div>
            </label>
            <p className='text-sm md:text-sm text-gray-700'>
              לקוחות יכולים לקבוע תור עד <strong className='underline'>{futureBookings.text}</strong> קדימה.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <select
              className="select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full"
              onChange={futureBookingsChangeHandler}
              value={futureBookings.value}>
              {
                defaultValues.futureBookings.map((time) => (
                  <option className='text-center' key={time.text}
                    value={time.value}>{time.text}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>

      <div className='border text-sm rounded-sm p-3'>
        <div
          className='md:space-x-5 md:space-x-reverse flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0'>
          <div className='md:w-1/2 space-y-1'>
            <label className="font-bold">
              <div className="label-text md:text-base text-gray-800">מעכשיו לעכשיו?</div>
            </label>
            <p className='text-sm md:text-sm text-gray-700'>
              לקוחות צריכים לקבוע תור מינימום <strong className='underline'>{minTimeBeforeBooking.text}</strong> מראש.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <select
              className="select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full"
              onChange={minTimeBeforeBookingChangeHandler}
              value={minTimeBeforeBooking.value}>
              {
                defaultValues.minTimeBeforeBooking.map((time) => (
                  <option className='text-center' key={time.text}
                    value={time.value}>{time.text}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>

      <div className='border text-sm rounded-sm p-3'>
        <div
          className='flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-5 md:space-x-reverse'>
          <div className='md:w-1/2 space-y-1'>
            <label className="font-bold flex items-center">
              <div className="label-text md:text-base text-gray-800">תורים מרובים</div>

            </label>
            <p className='text-sm md:text-sm text-gray-700'>
              האם לאפשר ללקוח לקבוע יותר מתור אחד, במידה וכבר יש לו תור עתידי?
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <select
              className='select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full'
              onChange={allowMultipleBookingsChangeHandler}
              value={timeSettings.limits.allowMultipleBookings}>
              <option className='text-center' value={false}>❌ אל תאפשר</option>
              <option className='text-center' value={true}>✅ אפשר</option>
            </select>
          </div>
        </div>
      </div>

      <div className='border text-sm rounded-sm p-3'>
        <div
          className='md:space-x-5 md:space-x-reverse flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0'>
          <div className='md:w-1/2 space-y-1'>
            <label className="font-bold flex items-center">
              <div className="label-text md:text-base text-gray-800">מתי לשלוח תזכורת?</div>
              <span
                className="inline-flex items-center justify-center px-2 py-0.5 mr-2 text-xs font-bold leading-none text-white bg-blue-400 rounded-full">
                חדש
              </span>
            </label>
            <p className='text-sm md:text-sm text-gray-700'>
              אני רוצה שלקוחות יקבלו תזכורת ובקשה לאשר הגעה כ- <strong className='underline'>{reminderTime.text}</strong> לפני התור.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <select
              className="select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full"
              onChange={reminderTimeChangeHandler}
              value={reminderTime.value}>
              {
                defaultValues.reminderTimes.map((time) => (
                  <option className='text-center' key={time.text}
                    value={time.value}>{time.text}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>

      <SettingsItem title={'אישור תורים ידני'} value={timeSettings.limits.approveAppointmentsManually} onChange={onChangeManualAppointmentApproval}
        options={[{ value: false, text: '❌ לא פעיל' }, { value: true, text: '✅ פעיל' }]}>
        האם תרצו לאשר תורים ידנית לפני שהם נכנסים ליומן שלכם?
      </SettingsItem>

      <ApproveAppointmentsManuallyModal open={approveAppointmentsManuallyModalOpen} setOpen={setApproveAppointmentsManuallyModalOpen}
        setTimeSettings={setTimeSettings} />
    </>
  )
}

export default AvailabilityLimits;