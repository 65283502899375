// contexts/auth.js
import React, { createContext, useContext, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash'
import { ERRORS } from '../constants'
const { INVALID_PHONE, ALREADY_EXISTS, PAYMENT_ALREADY_COMPLETE, INVALID_EMAIL, EMPTY_NAME } = ERRORS

const APIContext = createContext({});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  console.log('Axios Request:', config.method, config.url, config.data)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log('Axios Response:', response.config.url, response.status, response.statusText, response.data);

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log('Axios Error:', error)
  return Promise.reject(error);
});

export const APIProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  const updateBusiness = async ({ details, timeSettings }) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/business/`, { details, timeSettings });
      setLoading(false);
    } catch (ex) {
      console.log('failed to update business', ex.toString(), ex)
      toast.error('עדכון פרטי העסק נכשל. נסו שוב מאוחר יותר או פנו לתמיכה.');
      setLoading(false);
    }
  }

  const getServices = async () => {
    try {
      const res = await axios.get(`/api/business/services/`);
      return res;
    } catch (ex) {
      console.log('failed to get services', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const addNewService = async ({ service, serviceProviders }) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/business/services/`, { service, serviceProviders });
      setLoading(false);
      return res;
    } catch (ex) {
      console.log('failed to add service', ex.toString(), ex)
      toast.error('הוספת השירות נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
      setLoading(false);
    }
  }

  const deleteService = async ({ serviceId }) => {
    try {
      const res = await axios.delete(`/api/business/services/${serviceId}/`);
      return res;
    } catch (ex) {
      console.log('failed to delete service', ex.toString(), ex)
      toast.error('המחיקה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateService = async ({ serviceId, service, serviceProviders }) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/business/services/${serviceId}/`, { service, serviceProviders });
      setLoading(false);
      return res;
    } catch (ex) {
      console.log('failed to update service', ex.toString(), ex)
      toast.error('עדכון השירות נכשל. נסו שוב מאוחר יותר או פנו לתמיכה.');
      setLoading(false);
    }
  }

  const setWizardComplete = async () => {
    try {
      const res = await axios.post(`/api/business/wizardComplete/`);
      return res;
    } catch (ex) {
      console.log('failed to complete wizard', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getWorkEvents = async ({ group, day, serviceProvider }) => {
    try {
      const res = await axios.get(`/api/business/appointments/?group=${group}${day ? '&day=' + day : ''}${serviceProvider ? '&serviceProvider=' + serviceProvider : ''}`);
      return res;
    } catch (ex) {
      console.log('failed to get appointments', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getEventsFeed = async url => {
    try {
      const res = await axios.get(`/api/business${url}`);
      return _.get(res, 'data.events.docs', []);
    } catch (ex) {
      console.log('failed to get events', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getPendingAppointments = async () => {
    try {
      const { data } = await axios.get(`/api/business/pendingAppointments/`)
      return data
    } catch (err) {
      throw err
    }
  }

  const getProductUpdates = async (url) => {
    try {
      const res = await axios.get(`/api/business${url}`);
      return _.get(res, 'data', []);
    } catch (ex) {
      console.log('failed to get product updates', ex.toString(), ex)
    }
  }

  const getStatsBox = async () => {
    try {
      const res = await axios.get(`/api/business/stats/`);
      return res;
    } catch (ex) {
      console.log('failed to get stats', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getBusinessDetails = async () => {
    try {
      const res = await axios.get(`/api/business/`);
      return res;
    } catch (ex) {
      console.log('failed to get stats', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const searchCustomers = async (url) => {
    try {
      const res = await axios.get(`/api${url}`);
      return _.get(res, 'data', []);
    } catch (ex) {
      console.log('failed to get search results', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getTotalCustomers = async () => {
    try {
      const res = await axios.get(`/api/business/customers/totalCustomers/`);
      return res
    } catch (ex) {
      console.log('failed to get total customers', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getCustomer = async (customerId) => {
    try {
      const res = await axios.get(`/api/business/customers/${customerId}/`);
      return res;
    } catch (ex) {
      console.log('failed to get customer', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateCustomer = async (customerId, params) => {
    try {
      const res = await axios.post(`/api/business/customers/${customerId}/`, params);
      return res;
    } catch (err) {
      let message = 'הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.'
      if (err.response?.status === 400) {
        if (err.response?.data === EMPTY_NAME) message = 'אנא הזינו שם מלא'
        else if (err.response?.data === INVALID_EMAIL) message = 'כתובת המייל לא תקנית'
      }
      toast.error(message)
    }
  }

  const deleteCustomer = async (customerId) => {
    try {
      const res = await axios.delete(`/api/business/customers/${customerId}/`);
      return res;
    } catch (ex) {
      console.log('failed to delete customer', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getTotalStats = async ({ start, end }) => {
    try {
      const { data } = await axios.get(`/api/business/stats/total/?start=${start}&end=${end}`);
      return data
    } catch (ex) {
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const saveBusinessDetails = async (details) => {
    try {
      const res = await axios.post(`/api/business/settings/updateBusinessDetails/`, details);
      return res;
    } catch (ex) {
      let message = 'הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.'
      if (ex.response.data.error === 'invalid phone') message = 'מס׳ הטלפון שהוזן אינו תקין'
      console.log('failed to save details', ex.toString(), ex)
      toast.error(message)
    }
  }

  const saveGeneralSettings = async (generalSettings) => {
    try {
      const { data } = await axios.post(`/api/business/settings/updateGeneralSettings/`, generalSettings)
      return data
    } catch (ex) {
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateSocialLinks = async (socialLinks) => {
    try {
      const res = await axios.post(`/api/business/settings/updateSocialLinks/`, socialLinks);
      return res;
    } catch (ex) {
      console.log('failed to save socialLinks', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateTimeSettings = async timeSettings => {
    try {
      const { data } = await axios.post(`/api/business/settings/updateTimeSettings/`, timeSettings)
      return data
    } catch (err) {
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const toggleServiceProviderAvailability = async ({ serviceProviderId, available }) => {
    try {
      const res = await axios.post(`/api/business/staff/toggleAvailability/`, { serviceProviderId, available });
      return res;
    } catch (ex) {
      console.log('failed to save toggleAvailability', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const toggleServiceAvailability = async ({ serviceId, available }) => {
    try {
      const res = await axios.post(`/api/business/services/toggleAvailability/`, { serviceId, available });
      return res;
    } catch (ex) {
      console.log('failed to save toggleServiceAvailability', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const addServiceProvider = async ({ firstName, lastName, email, phone }) => {
    try {
      const res = await axios.post(`/api/business/staff/`, { firstName, lastName, email, phone });
      return res;
    } catch (ex) {
      console.log('failed to addServiceProvider', ex.toString(), ex)
      let errorMessage = 'הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.'
      if (ex?.response?.status === 400) {
        const { data } = ex.response
        if (data === INVALID_PHONE) errorMessage = 'מספר הטלפון שהוזן לא תקין'
        else if (data === ALREADY_EXISTS) errorMessage = 'איש הצוות כבר רשום בצוות'
      }
      toast.error(errorMessage)
    }
  }

  const createNewCustomer = async ({ customer }) => {
    try {
      const res = await axios.put(`/api/business/customers/`, customer);
      return res;
    } catch (err) {
      let message = 'אופס... בדקו שכל הפרטים (והטלפון) שהזנתם תקינים ונסו שנית'
      if (err.response?.status === 400) {
        if (err.response?.data === INVALID_PHONE) message = 'מספר הטלפון שהוזן אינו תקין'
        else if (err.response?.data === INVALID_EMAIL) message = 'כתובת המייל לא תקנית'
      }
      toast.error(message)
    }
  }

  const getBusinessAvailableTimes = async ({ day, serviceProviderId, serviceLength, currentDateTime }) => {
    try {
      const params = { day, serviceProviderId, serviceLength };
      if (currentDateTime) params.currentDateTime = currentDateTime;

      const { data } = await axios.post(`/api/business/calendar/checkAvailability/`, params);
      return data
    } catch (err) {
      throw 'נראה שמשהו השתבש, נסו שוב מאוחר יותר או פנו לתמיכה'
    }
  }

  const addManualAppointment = async (appointment) => {
    try {
      const { data } = await axios.put(`/api/business/appointments/`, appointment);
      return data
    } catch (ex) {
      console.log('failed to book manual appointment', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const cancelAppointment = async (appointmentId) => {
    try {
      const res = await axios.post(`/api/business/appointments/${appointmentId}/cancel/`);
      return res;
    } catch (ex) {
      console.log('failed to cancel appointment', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const resendAppointmentDetails = async (appointmentId) => {
    try {
      const res = await axios.post(`/api/business/appointments/${appointmentId}/resendDetails/`);
      return res;
    } catch (ex) {
      console.log('failed to resend appointment details', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const updateAppointment = async (appointmentId, appointment) => {
    try {
      const { data } = await axios.post(`/api/business/appointments/${appointmentId}/`, appointment);
      return data
    } catch (ex) {
      console.log('failed to update appointment', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }
  const deletePastAppointment = async (appointmentId) => {
    try {
      const res = await axios.delete(`/api/business/appointments/${appointmentId}/`);
      return res;
    } catch (ex) {
      console.log('failed to delete appointment', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const getDaysWithWork = async (url) => {
    console.log(url);
    try {
      const res = await axios.get(`/api/business${url}`);
      return res;
    } catch (ex) {
      console.log('failed to get daysWithAppointments', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const addAvailability = async (availability) => {
    try {
      const res = await axios.put(`/api/business/availability/`, availability);
      return res;
    } catch (ex) {
      console.log('failed to add availability', ex.toString(), ex)
      if (ex?.response?.status === 400) {
        throw ex.response.data
      } else toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateAvailability = async (availability) => {
    try {
      const res = await axios.post(`/api/business/availability/${availability._id}/`, availability);
      return res;
    } catch (ex) {
      console.log('failed to update availability', ex.toString(), ex)
    }
  }

  const getAvailabilities = async (type, serviceProviderId) => {
    try {
      const res = await axios.get(`/api/business/availability/${(type === 'holiday') ? `?type=${type}&serviceProviderId=${serviceProviderId}` : ''}`);
      return _.get(res, 'data', []);
    } catch (ex) {
      console.log('failed to get availability', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const toggleAvailabilityStatus = async (availabilityId) => {
    try {
      const res = await axios.post(`/api/business/availability/`, { availabilityId });
      return res;
    } catch (ex) {
      console.log('failed to toggle availability', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const deleteAvailability = async (availabilityId) => {
    try {
      const res = await axios.delete(`/api/business/availability/${availabilityId}`);
      return res;
    } catch (ex) {
      console.log('failed to delete availability', ex.toString(), ex)
      toast.error('הפעולה נכשלה');
    }
  }

  const getPublicHolidays = async (startDate, endDate) => {
    if (!endDate) endDate = startDate
    try {
      const res = await axios.get(`https://www.hebcal.com/hebcal/?v=1&cfg=json&maj=on&mod=on&lg=h&start=${startDate}&end=${endDate}`);
      if (res && res.data) {
        return _.get(res, 'data.items', [])
      }
      return [];
    } catch (ex) {
      console.log('failed to getPublicHolidays', ex.toString(), ex)
      if (endDate) toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const updatePublicMessage = async details => {
    try {
      const { data } = await axios.post(`/api/business/marketing/publicMessage/`, details)
      return data
    } catch (err) {
      console.log('failed to save marketing options', err.toString(), err)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const updateCustomPopup = async details => {
    try {
      const { data } = await axios.post(`/api/business/marketing/popup/`, details)
      return data
    } catch (err) {
      console.log('failed to save marketing options', err.toString(), err)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const updateHappyBirthdaySetting = async shouldActivate => {
    try {
      const { data } = await axios.post(`/api/business/marketing/happyBirthdayMessage/`, { shouldActivate })
      return data
    } catch (err) {
      console.log('failed to save marketing options', err.toString(), err)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const getSubscriptionInfo = async () => {
    try {
      const res = await axios.get(`/api/business/billing/plan/`);
      return res;
    } catch (ex) {
      console.log('failed to get account details', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const sendBulkMessages = async (details) => {
    try {
      const res = await axios.put(`/api/business/marketing/smsCampaigns/`, details);
      return res;
    } catch (ex) {
      console.log('failed to send bulk messages', ex.toString())
      toast.error('הפעולה נכשלה');
    }
  }

  const getSMSCampaigns = async () => {
    try {
      const res = await axios.get(`/api/business/marketing/smsCampaigns/`);
      return res;
    } catch (ex) {
      console.log('failed to get campaigns', ex.toString())
      toast.error('הפעולה נכשלה');
    }
  }

  const getPlanCheckoutURL = async ({ planName, upgrade, changeCard, hasDebt }) => {
    try {
      const res = await axios.post(`/api/business/billing/selectPlan/`, {
        planName,
        upgrade,
        changeCard,
        hasDebt
      });
      return res;
    } catch (ex) {
      console.log('failed to get checkout URL', ex.toString())
      toast.error('הפעולה נכשלה');
    }
  }

  const getPlanCancelDetails = async () => {
    try {
      const res = await axios.get(`/api/business/billing/plan/cancel/`);
      return res;
    } catch (ex) {
      console.log('failed to getPlanCancelDetails', ex.toString())
      toast.error('הפעולה נכשלה');
    }
  }

  const executePlanUpgrade = async ({ newPlan, isDowngrading }) => {
    try {
      const res = await axios.post(`/api/business/billing/plan/upgrade/`, { newPlan, isDowngrading });
      return res;
    } catch (ex) {
      console.log('failed to get checkout URL', ex.toString())
      toast.error('הפעולה נכשלה');
    }
  }

  const executePlanCancel = async () => {
    try {
      const res = await axios.post(`/api/business/billing/plan/cancel/`);
      return res;
    } catch (ex) {
      console.log('failed to executePlanCancel', ex.toString())
      toast.error('הפעולה נכשלה');
    }
  }


  const archiveService = async (serviceId) => {
    try {
      const res = await axios.post(`/api/business/services/${serviceId}/archive/`);
      return res;
    } catch (ex) {
      console.log('failed to archive service', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getServiceProvider = async (serviceProviderId) => {
    try {
      const res = await axios.get(`/api/business/staff/${serviceProviderId}/`);
      return res;
    } catch (ex) {
      console.log('failed to get service provider details', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateServiceProvider = async (serviceProviderId, details) => {
    try {
      const { data } = await axios.post(`/api/business/staff/${serviceProviderId}/`, details)
      return data
    } catch (ex) {
      let message = 'הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.'
      if (ex?.response?.status === 400) message = 'הפרטים שהוזנו לא תקינים, אנא בדקו אותם ונסו שנית'
      throw message
    }
  }

  const archiveServiceProvider = async (serviceProviderId) => {
    try {
      const res = await axios.post(`/api/business/staff/${serviceProviderId}/archive/`);
      return res;
    } catch (ex) {
      console.log('failed to archive service provider', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const updateOrder = async (services) => {
    try {
      const res = await axios.post(`/api/business/services/updateOrder/`, services);
      return res;
    } catch (ex) {
      console.log('failed to update service order', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const exportTaxReports = async (params) => {
    try {
      const res = await axios.post(`/api/business/stats/reports/exportTaxReports/`, params);
      return res;
    } catch (ex) {
      console.log('failed to exportTaxReports', ex.toString(), ex)
      return ex.response && ex.response.data.error ? ex.response.data : { error: ex };
    }
  }

  const exportAppointmentsReports = async (params) => {
    try {
      const res = await axios.post(`/api/business/stats/reports/exportAppointmentsReports/`, params);
      return res;
    } catch (ex) {
      console.log('failed to exportAppointmentsReports', ex.toString(), ex)
      return ex.response && ex.response.data.error ? ex.response.data : { error: ex };
    }
  }

  const exportCustomersReports = async () => {
    try {
      const res = await axios.post(`/api/business/stats/reports/exportCustomersReports/`, {});
      return res;
    } catch (ex) {
      console.log('failed to exportCustomersReports', ex.toString(), ex)
      return ex.response && ex.response.data.error ? ex.response.data : { error: ex };
    }
  }

  const getBusinessInvoices = async () => {
    try {
      const res = await axios.get(`/api/business/billing/invoices/`);
      return res;
    } catch (ex) {
      console.log('failed to get invoices', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getDebtDetails = async () => {
    try {
      const res = await axios.get(`/api/business/billing/debtDetails/`)
      return res
    } catch (ex) {
      console.log('failed to get debt details', ex.toString(), ex)
      toast.error('הפעולה נכשלה. נסה שוב מאוחר יותר או פנה לתמיכה.')
    }
  }

  const saveHolidayAvailabilities = async (availabilities) => {
    try {
      const res = await axios.post(`/api/business/availability/holidayAvailabilities`, availabilities)
      return res
    } catch (ex) {
      console.log('failed to update service provider details', ex.toString(), ex)
      if (ex?.response?.status === 400) {
        throw new Error('אופס... נראה ששעת ההתחלה שבחרתם היא *אחרי* שעת הסיום (ולא ההיפך). בצעו את השינוי ונסו שוב')
      } else toast.error('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.');
    }
  }

  const getHolidayAvailability = async (selectedDate) => {
    try {
      const res = await axios.get(`/api/business/availability/holidayAvailabilities/?selectedDate=${selectedDate}`)
      return res.data;
    } catch (ex) {
      console.log('failed to get holiday availability', ex.toString(), ex)
    }
  }

  const getSmsReminder = async (shipmentId) => {
    try {
      const res = await axios.get(`/api/business/smsMessage/?shipmentId=${shipmentId}`)
      return res.data;
    } catch (ex) {
      console.log('failed to get holiday availability', ex.toString(), ex)
    }
  }

  const sendMessageToCustomers = async message => {
    try {
      const res = await axios.post(`/api/business/messages/`, { message })
      return res.data;
    } catch (ex) {
      if (ex?.response?.status === 400) {
        throw ex.response.data
      } else throw ('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const addSingleGroup = async (group, approved = false) => {
    try {
      const { data } = await axios.put('/api/business/groups/singleGroup/', { group, approved })
      return data
    } catch (ex) {
      if (ex?.response?.status === 400) {
        throw ex.response.data
      } else throw ('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const addRecurringGroup = async (group, approvedEvents = []) => {
    try {
      const { data } = await axios.put('/api/business/groups/recurringGroup/', { group, approvedEvents })
      return data
    } catch (ex) {
      throw ('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const updateSingleGroup = async (group, approved = false) => {
    try {
      const { data } = await axios.post('/api/business/groups/singleGroup/', { group, approved })
      return data
    } catch (ex) {
      if (ex?.response?.status === 400) {
        throw ex.response.data
      } else throw ('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const updateRecurringGroup = async (group, approvedEvents = []) => {
    try {
      const { data } = await axios.post('/api/business/groups/recurringGroup/', { group, approvedEvents })
      return data
    } catch (ex) {
      throw ('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const informRegisteredCustomersOnGroupUpdate = async (groupId, seriesId) => {
    try {
      const { data } = await axios.post(`/api/business/groups/${groupId}/informRegisteredCustomers/?seriesId=${seriesId}`)
      toast.success('הלקוחות עודכנו בהצלחה')
      return data
    } catch (ex) {
      toast.error('שליחת ההודעות נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const cancelGroup = async (groupId, seriesId) => {
    try {
      await axios.delete(`/api/business/groups/${groupId}/?seriesId=${seriesId}`)
    } catch (err) {
      throw ('לא הצלחנו לבטל את השיעור. נסו שוב מאוחר יותר או פנו לתמיכה')
    }
  }

  const addCustomerToGroup = async (customerId, groupId) => {
    try {
      const { data } = await axios.post(`/api/business/groups/${groupId}/register`, { customerId })
      return data
    } catch (err) {
      console.log('err:', err)
      throw ('הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.')
    }
  }

  const resendGroupDetails = async groupId => {
    try {
      await axios.post(`/api/business/groups/${groupId}/resendDetails/`)
    } catch (ex) {
      console.log('Failed to resend group details', ex.toString(), ex)
      throw ex.response.data
    }
  }

  const getServiceProviders = async () => {
    try {
      const { data } = await axios.get(`/api/business/staff/`)
      return data
    } catch (ex) {
      console.log('ex:', ex)
    }
  }

  const handleAppointmentPayment = async (appointmentId, paymentDetails) => {
    try {
      const { data } = await axios.post(`/api/business/appointments/${appointmentId}/payment`, { appointmentId, paymentDetails })
      return data
    } catch (err) {
      console.log('err:', err)
      let message = 'הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.'
      if (err.response?.status === 400 && err.response?.data === PAYMENT_ALREADY_COMPLETE) message = 'הלקוח כבר חויב על תור זה בעבר'
      throw (message)
    }
  }

  const updateCustomTextMessage = async (message, type) => {
    try {
      const { data } = await axios.post(`/api/business/marketing/customTextMessage`, { message, type })
      return data
    } catch (err) {
      if (err.response?.status === 400) throw 'יש להוסיף את כל הפרמטרים להודעה'
      throw 'הפעולה נכשלה. נסו שוב מאוחר יותר או פנו לתמיכה.'
    }
  }


  const updateBusinessApprovalStatus = async ({ appointmentId, shouldApprove }) => {
    try {
      const { data } = await axios.put(`/api/business/appointments/${appointmentId}/updateBusinessApprovalStatus`, { shouldApprove })
      return data
    } catch (err) {
      throw err
    }
  }

  return (
    <APIContext.Provider
      value={{
        updateBusiness,
        addNewService,
        getServices,
        deleteService,
        updateService,
        setWizardComplete,
        getWorkEvents,
        getEventsFeed,
        getStatsBox,
        getBusinessDetails,
        searchCustomers,
        getTotalCustomers,
        getCustomer,
        updateCustomer,
        deleteCustomer,
        getTotalStats,
        saveBusinessDetails,
        updatePublicMessage,
        saveGeneralSettings,
        updateSocialLinks,
        updateTimeSettings,
        toggleServiceProviderAvailability,
        toggleServiceAvailability,
        addServiceProvider,
        createNewCustomer,
        getBusinessAvailableTimes,
        addManualAppointment,
        getDaysWithWork,
        cancelAppointment,
        updateAppointment,
        deletePastAppointment,
        getProductUpdates,
        addAvailability,
        updateAvailability,
        getAvailabilities,
        toggleAvailabilityStatus,
        deleteAvailability,
        getPublicHolidays,
        getSubscriptionInfo,
        updateOrder,
        getServiceProvider,
        archiveServiceProvider,
        updateServiceProvider,
        loading,
        sendBulkMessages,
        getSMSCampaigns,
        getPlanCheckoutURL,
        executePlanUpgrade,
        archiveService,
        exportTaxReports,
        exportAppointmentsReports,
        exportCustomersReports,
        getBusinessInvoices,
        getPlanCancelDetails,
        executePlanCancel,
        resendAppointmentDetails,
        getDebtDetails,
        saveHolidayAvailabilities,
        getHolidayAvailability,
        getSmsReminder,
        sendMessageToCustomers,
        getServiceProviders,
        updateCustomPopup,
        addSingleGroup,
        addRecurringGroup,
        cancelGroup,
        addCustomerToGroup,
        resendGroupDetails,
        updateSingleGroup,
        updateRecurringGroup,
        informRegisteredCustomersOnGroupUpdate,
        updateHappyBirthdaySetting,
        handleAppointmentPayment,
        updateCustomTextMessage,
        updateBusinessApprovalStatus,
        getPendingAppointments
      }}>
      {children}
    </APIContext.Provider>
  )
}

export const useAPI = () => useContext(APIContext)