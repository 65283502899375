import React from 'react'
import Modal from '../../../common/Modal'

function ApproveAppointmentsManuallyModal({ open, setOpen, setTimeSettings }) {
    function confirmAutoApprove() {
        setTimeSettings(prevTimeSettings => ({
            ...prevTimeSettings,
            limits: {
                ...prevTimeSettings.limits,
                approveAppointmentsManually: false
            }
        }))
        setOpen(false)
    }

    return (
        <Modal open={open} setOpen={setOpen}>
            <div className='mb-4'>
                התורים שנמצאים כרגע בהמתנה יאושרו באופן אוטומטי. להמשיך?
            </div>
            <div className="bg-gray-50 flex flex-row w-full gap-3 justify-end">
                <button
                    type="button"
                    className="w-1/4 items-center inline-flex justify-center rounded-md border h-10 border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:text-sm"
                    onClick={() => setOpen(false)}>
                    ביטול
                </button>
                <button
                    type="button"
                    className="w-1/4 inline-flex justify-center text-center disabled:opacity-50 rounded-md border h-10 flex items-center border-transparent shadow-sm px-10 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 sm:text-sm"
                    onClick={confirmAutoApprove}>
                    המשך
                </button>
            </div>
        </Modal>
    )
}

export default ApproveAppointmentsManuallyModal