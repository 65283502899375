import React, { useState, useEffect } from 'react'
import { LuCalendarClock } from "react-icons/lu"
import useScrollBlock from '../../../lib/useScrollBlock'
import PendingAppointmentsList from './PendingAppointmentsList'
import { useAPI } from '../../../contexts/api'

function PendingAppointmentsCenter() {
    const [showList, setShowList] = useState(false)
    const [blockScroll, allowScroll] = useScrollBlock()
    const { getPendingAppointments } = useAPI()
    const [hasError, setHasError] = useState(false)
    const [appointments, setAppointments] = useState([])
    const [loading, setLoading] = useState(false)

    const toggleList = ev => {
        ev.preventDefault()
        !showList ? blockScroll() : allowScroll()
        if (!showList) loadAppointments()
        setShowList(!showList)
    }

    useEffect(() => {
        loadAppointments()
    }, [])

    async function loadAppointments() {
        setLoading(true)

        try {
            const appointments = await getPendingAppointments()
            setAppointments(appointments)
        } catch (err) {
            setHasError(true)
        }
        setLoading(false)
    }

    return (
        <div className="relative">
            <button onClick={toggleList}
                className="z-50 block text-2xl flex items-center rounded-md bg-white hover:bg-gray-100 focus:bg-gray-100 p-2 lg:p-3 text-gray-600 focus:outline-none">
                <LuCalendarClock />
                {appointments.length > 0 &&
                    <div className='text-white bg-red-500 absolute flex items-center justify-center rounded-full text-center pending-appointments-counter'>
                        {appointments.length > 9 ? '9+' : appointments.length}
                    </div>}
            </button>

            {showList &&
                <>
                    <div className="fixed inset-0 h-full w-full z-10" onClick={toggleList} />
                    <div
                        className="fixed top-0 sm:top-auto sm:absolute w-full h-full sm:h-auto sm:w-96 left-0 sm:mt-1 bg-white sm:rounded-md border shadow-lg overflow-hidden z-20">
                        <PendingAppointmentsList toggle={toggleList} appointments={appointments} loading={loading} hasError={hasError} setAppointments={setAppointments} loadAppointments={loadAppointments} />
                    </div>
                </>
            }
        </div>
    )
}

export default PendingAppointmentsCenter