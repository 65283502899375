import React from 'react'
import { GrClose } from "react-icons/gr"
import PendingAppointmentItem from './PendingAppointmentItem'
import FeedItemLoadingSkeleton from './FeedItemLoadingSkeleton'
import { IoIosCloseCircleOutline } from "react-icons/io"
import useUser from '../../../lib/useUser'

function PendingAppointmentsList({ toggle, appointments, loading, hasError, setAppointments, loadAppointments }) {
    const { user } = useUser()

    return (
        <>
            <>
                <div className="bg-white mx-auto w-full h-full divide-y sm:rounded-md overflow-y-auto sm:max-h-64 pt-14 sm:pt-0">
                    <div
                        className='font-medium bg-gray-100 fixed sm:sticky w-full top-0 sm:-mt-1 p-1 pr-4 z-40 border-b border-gray-200 flex items-center justify-between'>
                        <div className='text-lg sm:text-base'>בהמתנה לאישור</div>
                        <button className='font-extrabold text-gray-700 sm:text-xl hover:bg-gray-200 p-3 sm:p-2 rounded text-2xl'
                            onClick={toggle}>
                            <GrClose />
                        </button>
                    </div>

                    {(appointments?.length > 0 && !hasError && !loading) &&
                        appointments.map(appointment => (
                            <PendingAppointmentItem key={appointment._id} appointment={appointment} setAppointments={setAppointments}
                                loadAppointments={loadAppointments} user={user} />
                        ))}

                    <div>
                        {(!loading && !appointments.length && !hasError)
                            && <div className='text-center font-medium text-gray-400 p-3'>אין תורים שממתינים לאישור</div>}
                        {(hasError && !loading) &&
                            <div className='text-center text-gray-800 p-3 flex items-center'>
                                <IoIosCloseCircleOutline className='text-red-600 my-1 ml-1' size={25} />
                                אירעה שגיאה בהצגת תורים שממתינים לאישור
                            </div>}
                        {loading && <FeedItemLoadingSkeleton />}
                    </div>
                </div>
            </>
        </>
    )
}

export default PendingAppointmentsList