import React, { useEffect, useState } from 'react';
import { AuthProvider } from '../../contexts/auth'
import { APIProvider } from '../../contexts/api'
import useUser from '../../lib/useUser'
import Loading from "../common/Loading";
import Link from "next/link";
import MainSidebar from "../common/MainSidebar";
import BusinessWizard from "./Business/wizard/BusinessWizard"
import { GrClose } from "react-icons/gr";
import { MdImportantDevices } from "react-icons/md";
import { useRouter } from 'next/router'
import _ from "lodash";
import { HiOutlineBriefcase } from "react-icons/hi";
import NotificationsCenter from "./Screens/NotificationsCenter";
import { RiShutDownLine } from "react-icons/ri";
import Script from 'next/script'
import PendingAppointmentsCenter from './Screens/PendingAppointmentsCenter'

function AppLayout({ children }) {
	const [routeTitle, setRouteTitle] = useState({ title: 'העסק שלי', icon: <HiOutlineBriefcase size={23} /> })
	const [showMobileMenu, setShowMobileMenu] = useState(false)
	const { user } = useUser({ redirectTo: '/app/login' })
	const [wizardStep, setWizardStep] = useState(_.get(user, 'business.details.wizardStep', 2))
	const router = useRouter()

	useEffect(() => {
		if (user) {
			if (user.business?.archived) router.push('/app/error')
			try {
				setWizardStep(_.get(user, 'business.details.wizardStep', 2))
			} catch (e) {
				console.error(e)
			}
		}
	}, [user]);

	useEffect(() => {
		const handleRouteChange = (url, { shallow }) => {
			setShowMobileMenu(false)
		}
		router.events.on('routeChangeStart', handleRouteChange)
		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
	})

	if (!user || user.isLoggedIn === false || user?.business?.archived) {
		return <Loading />
	}

	return (
		<AuthProvider>
			<APIProvider>
				<Script
					id="privy-script-code"
					dangerouslySetInnerHTML={{
						__html: `   var _d_site = _d_site || '523C285C1F92A575C118C13B';
   (function(p, r, i, v, y) {
     p[i] = p[i] || function() { (p[i].q = p[i].q || []).push(arguments) };
     v = r.createElement('script'); v.async = 1; v.src = 'https://widget.privy.com/assets/widget.js';
     y = r.getElementsByTagName('script')[0]; y.parentNode.insertBefore(v, y);
   })(window, document, 'Privy');`,
					}}
				/>
				<div onClick={() => setShowMobileMenu(!showMobileMenu)}
					className={`${showMobileMenu ? 'block' : 'hidden'} block lg:hidden w-full h-full fixed top-0 left-0 bg-black opacity-75 z-50`}>
				</div>
				<div className={`flex flex-wrap w-full bg-gray-50 min-h-screen top-0`}>
					<div
						className={` ${showMobileMenu ? 'rounded-tl-2xl shadow-2xl' : 'translate-x-full'} z-50 lg:z-auto sidebar bg-white lg:bg-gray-50 text-blue-100 w-72 lg:w-64 py-1 px-2 lg:py-0 lg:px-0 fixed inset-y-0 right-0 transform  lg:-translate-x-0 transition duration-200 ease-in-out`}>
						<div className='flex items-center'>
							<div className='p-2 lg:mt-1 flex items-center sm:justify-center sm:mx-auto'>
								<Link href='/app/'>
									<a href='/app/'>
										<img src='/img/logo.png' className="w-36 lg:w-40 mx-auto -mr-2" alt='logo' />
									</a>
								</Link>
								<Link href='/app/logout/'>
									<a href='/app/logout/' className='-mr-2 md:mr-4 bg-white hover:bg-gray-50 h-7 font-medium lg:h-8 flex items-center text-gray-500 text-sm -mt-1 pr-0.5 pl-1.5 flex items-center justify-between space-x-1 space-x-reverse lg:px-2 border border-gray-200 rounded shadow-sm'>
										<RiShutDownLine className='hidden sm:block' size={17} />
										<RiShutDownLine className='block sm:hidden' size={15} />
										<span className='text-xs tracking-tight'>יציאה</span>
									</a>
								</Link>
							</div>
							<button className='text-black absolute left-3 top-4 lg:hidden'
								onClick={() => setShowMobileMenu(!showMobileMenu)}>
								<GrClose size={30} />
							</button>
						</div>
						<MainSidebar setRouteTitle={setRouteTitle} user={user} />
					</div>

					<div className='lg:mr-64 w-full z-20 shadow-lg lg:border-r border-gray-200 bg-white rounded-tr-xl'>
						<div
							className='md:border-b bg-white w-full shadow lg:shadow-none fixed top-0 z-30 lg:pl-64 lg:rounded-tr-xl'>
							<div className='flex items-center block justify-between pl-1 pr-3 lg:p-6 lg:pl-2 h-14 lg:h-16'>
								<div className='lg:hidden flex-1 flex items-center'>
									{(_.get(user, 'business.details.wizardStep', 2) === 6 || wizardStep === 6) &&
										<button className='font-medium bg-gray-600 rounded p-1 px-2 text-white shadow border-gray-700 border outline-none focus:outline-none hover:outline-none'
											onClick={() => setShowMobileMenu(!showMobileMenu)}>
											תפריט
										</button>
									}
								</div>
								<div
									className='text-xl lg:text-3xl font-bold sm:font-medium text-gray-700 text-center justify-center lg:justify-start flex items-center flex-1'>
									<span className='whitespace-nowrap'>{routeTitle.title}</span>
								</div>
								<div className='flex-1 flex item-center justify-end'>
									{user.business.timeSettings.limits.approveAppointmentsManually && <PendingAppointmentsCenter />}
									<div>
										<a target="_blank" href={`https://zmantov.co.il/b/${user.business.pageCode}/`}
											className="z-10 flex items-center text-2xl block rounded-md bg-white hover:bg-gray-100 p-2 lg:p-3 text-gray-600 focus:outline-none">
											<MdImportantDevices />
										</a>
									</div>
									<div className='flex'>
										<NotificationsCenter />
									</div>
								</div>
							</div>
						</div>
						<main className='sm:p-4 relative py-16 sm:py-20 pb-40 min-w-full w-full rounded-tr-xl'>
							<BusinessWizard wizardStep={wizardStep} children={children} />
						</main>
					</div>
				</div>
			</APIProvider>
		</AuthProvider>
	);
}

export default AppLayout;