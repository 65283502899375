import React from 'react';

function FeedItemLoadingSkeleton() {
	return (
		<div className='animate-pulse divide-y'>
			<div className='flex items-center w-full px-4 py-3'>
				<div className="inline-block h-full rounded-full bg-white flex-shrink-0">
					<div className="w-8 h-8 bg-gray-200 rounded-full" />
				</div>
				<div className='mr-4'>
					<div className='flex flex-col space-y-3'>
						<div className="h-3 bg-gray-200 rounded-full w-36" />
						<div className="text-gray-400 font-medium tracking-tight text-sm">
							<div className="h-2 bg-gray-200 rounded-full w-24" />
						</div>
					</div>
				</div>
			</div>
			<div className='flex items-center w-full px-4 py-3'>
				<div className="inline-block h-full rounded-full bg-white flex-shrink-0">
					<div className="w-8 h-8 bg-gray-200 rounded-full" />
				</div>
				<div className='mr-4'>
					<div className='flex flex-col space-y-3'>
						<div className="h-3 bg-gray-200 rounded-full w-36" />
						<div className="text-gray-400 font-medium tracking-tight text-sm">
							<div className="h-2 bg-gray-200 rounded-full w-24" />
						</div>
					</div>
				</div>
			</div>
			<div className='flex items-center w-full px-4 py-3'>
				<div className="inline-block h-full rounded-full bg-white flex-shrink-0">
					<div className="w-8 h-8 bg-gray-200 rounded-full" />
				</div>
				<div className='mr-4'>
					<div className='flex flex-col space-y-3'>
						<div className="h-3 bg-gray-200 rounded-full w-36" />
						<div className="text-gray-400 font-medium tracking-tight text-sm">
							<div className="h-2 bg-gray-200 rounded-full w-24" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeedItemLoadingSkeleton;