import React, { useState } from 'react'
import moment from 'moment-timezone'
import LoaderIcon from '../../common/LoaderIcon'
import { useAPI } from '../../../contexts/api'
import { toast } from 'react-toastify'

function PendingAppointmentItem({ appointment, setAppointments, loadAppointments, user }) {
    const [approvingAppointment, setApprovingAppointment] = useState(false)
    const [decliningAppointment, setDecliningAppointment] = useState(false)
    const { updateBusinessApprovalStatus } = useAPI()

    const { serviceProvider, customer, service } = appointment

    async function onUpdateBusinessApprovalStatus(shouldApprove) {
        const setLoading = shouldApprove ? setApprovingAppointment : setDecliningAppointment
        setLoading(true)

        try {
            await updateBusinessApprovalStatus({ appointmentId: appointment._id, shouldApprove })
            setAppointments(prevAppointments => prevAppointments.filter(prevAppointment => prevAppointment._id !== appointment._id))
        } catch (err) {
            if (err.response?.status === 400) {
                toast.error('התור כבר טופל ואינו במצב המתנה')
                loadAppointments()
            } else toast.error(`לא הצלחנו ${shouldApprove ? 'לאשר' : 'לדחות'} את התור, נסו שנית מאוחר יותר או פנו לתמיכה`)
        }
        setLoading(false)
    }

    return (
        <div className="relative overflow-hidden">
            <div className="flex items-center w-full">
                <div className="order-1 bg-white w-auto text-xs space-y-1 p-3">
                    <div className="text-xs text-gray-900 text-opacity-100 space-x-1 space-x-reverse mb-2">
                        {customer.firstName} {customer.lastName} קבע תור ל{service.name} ביום {moment(appointment.date).format('dddd, LL בשעה HH:mm')} {user?.serviceProviders?.length > 1 && `אצל ${serviceProvider.firstName} ${serviceProvider.lastName}`}
                    </div>
                    <div>
                        {/* <span className='text-gray-500'>יום {moment(appointment.date).format('dddd, L בשעה HH:mm')}</span> */}
                    </div>
                    <div className='flex gap-2 my-2 w-3/4' style={{ maxWidth: '240px' }}>
                        <button onClick={ev => onUpdateBusinessApprovalStatus(true)} disabled={approvingAppointment || decliningAppointment}
                            className='border text-sm h-7 flex-1 border-green-500 text-green-500 p-2 hover:text-green-600 hover:border-green-600 hover:bg-green-50 rounded w-full flex justify-center items-center ease-in-out w-1/2'>
                            <LoaderIcon size={20} spin={approvingAppointment}>אשר</LoaderIcon>
                        </button>
                        <button onClick={ev => onUpdateBusinessApprovalStatus(false)} disabled={approvingAppointment || decliningAppointment}
                            className='border text-sm h-7 flex-1 border-red-500 text-red-500 p-2 hover:text-red-600 hover:border-red-600 hover:bg-red-50 rounded flex justify-center items-center ease-in-out w-1/2'>
                            <LoaderIcon size={20} spin={decliningAppointment}>דחה</LoaderIcon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PendingAppointmentItem